// @flow

import * as React from 'react';
import type { JSONProps } from 'common/types';

type LazyComponent = Promise<React.ComponentType<JSONProps<any>>>;

export async function MeetingPackageListApp(): LazyComponent {
  return (await import('packages/show')).MeetingPackageListApp;
}

export async function CelebrationPackageListApp(): LazyComponent {
  return (await import('packages/show')).CelebrationPackageListApp;
}

export async function ExperiencePackageListApp(): LazyComponent {
  return (await import('packages/show')).ExperiencePackageListApp;
}

export async function VenuePackageListApp(): LazyComponent {
  return (await import('packages/show')).VenuePackageListApp;
}

export async function ProfileEditRichTextEditor(): LazyComponent {
  return (
    await import(
      'profiles/common/edit/components/ProfileEditRichTextEditor.rails'
    )
  ).default;
}

export async function InquiryFormFields(): LazyComponent {
  return (await import('inquiry/components/InquiryFormFields.rails')).default;
}

export async function AvailabilitySourceForm(): LazyComponent {
  return (await import('reservations/components/AvailabilitySourceForm'))
    .default;
}

export async function PhoneNumberForm(): LazyComponent {
  return (await import('phoneNumbers/components/PhoneNumberForm')).default;
}

export async function ViewPhoneNumberButton(): LazyComponent {
  return (await import('phoneNumbers/components/ViewPhoneNumberButton.rails'))
    .default;
}

export async function SiblingGroupPreviewApp(): LazyComponent {
  return (
    await import('groups/venues/show/components/SiblingGroupPreviewApp.rails')
  ).default;
}

export async function SimilarVenueList(): LazyComponent {
  return (
    await import(
      'profiles/venues/show/components/similarVenues/SimilarVenueList.rails'
    )
  ).default;
}

export async function Rating(): LazyComponent {
  return (await import('common/components/Rating')).default;
}

export async function RatingBoxAccordion(): LazyComponent {
  return (await import('common/components/accordion/RatingBoxAccordion'))
    .default;
}

export async function AccordionPanel(): LazyComponent {
  return (await import('common/components/accordion/AccordionPanel')).default;
}

export async function CommissionRoot(): LazyComponent {
  return (await import('commission/components/CommissionRoot.rails')).default;
}

export async function PackageEditorRoot(): LazyComponent {
  return (await import('packages/edit/components/PackageEditorRoot.rails'))
    .default;
}

export async function VenueInquiryConversationRoot(): LazyComponent {
  return (
    await import(
      'conversations/show/components/venueInquiry/conversation/ConversationRoot.rails'
    )
  ).default;
}

export async function CateringInquiryConversationApp(): LazyComponent {
  return (
    await import(
      'conversations/show/components/cateringInquiry/ConversationApp.rails'
    )
  ).default;
}

export async function ReadOnlyVenueInquiryConversationApp(): LazyComponent {
  return (
    await import(
      'conversations/show/components/venueInquiry/readOnly/ConversationApp.rails'
    )
  ).default;
}

export async function MarkAsRepliedRoot(): LazyComponent {
  return (
    await import(
      'conversations/show/components/venueInquiry/markAsReplied/MarkAsRepliedRoot.rails'
    )
  ).default;
}

export async function VenueGroupEditorApp(): LazyComponent {
  return (
    await import('groups/venues/edit/components/VenueGroupEditorApp.rails')
  ).default;
}

export async function TranslatedVenueDescription(): LazyComponent {
  return (
    await import('profiles/venues/show/components/TranslatedVenueDescription')
  ).default;
}

export async function MoveToSearchLinks(): LazyComponent {
  return (
    await import('profiles/venues/show/components/MoveToSearchLinks.rails')
  ).default;
}

export async function SendCopy(): LazyComponent {
  return (await import('thanks/components/SendCopy')).default;
}

export async function MessageTemplateEditorApp(): LazyComponent {
  return (
    await import('messageTemplates/components/MessageTemplateEditorApp.rails')
  ).default;
}

export async function MultiSelect(): LazyComponent {
  return (await import('common/components/MultiSelect.rails')).default;
}

export async function VenuePageInquiryForm(): LazyComponent {
  return (await import('inquiry/components/VenuePageInquiryForm.rails'))
    .default;
}

export async function SearchableInquiryIndex(): LazyComponent {
  return (
    await import('conversations/index/components/SearchableInquiryIndex.rails')
  ).default;
}

export async function FeaturedEventTypeField(): LazyComponent {
  return (
    await import('staff/featuredVenuesEdit/components/FeaturedEventTypeField')
  ).default;
}

export async function FeaturedVenuesInventory(): LazyComponent {
  return (
    await import(
      'staff/featuredVenuesInventory/components/FeaturedVenuesInventory.rails'
    )
  ).default;
}

export async function EditOrganizationTabs(): LazyComponent {
  return (await import('staff/organization/components/EditOrganizationTabs'))
    .default;
}

export async function SpamInquiriesTabs(): LazyComponent {
  return (await import('staff/spamInquiries/components/SpamInquiriesTabs'))
    .default;
}

export async function EditVenueServices(): LazyComponent {
  return (await import('staff/organization/components/EditVenueServices'))
    .default;
}

export async function EditVenueTypes(): LazyComponent {
  return (await import('staff/organization/components/EditVenueTypes')).default;
}

export async function ProfileAnnouncementsEditorApp(): LazyComponent {
  return (
    await import(
      'staff/organization/components/ProfileAnnouncementsEditorApp.rails'
    )
  ).default;
}

export async function CateringSearch(): LazyComponent {
  return (await import('catering/search/components/CateringSearch.rails'))
    .default;
}

export async function CateringBasicInformation(): LazyComponent {
  return (await import('profiles/catering/show/cateringComponents.rails'))
    .CateringBasicInformation;
}
export async function CateringDetailedInformation(): LazyComponent {
  return (await import('profiles/catering/show/cateringComponents.rails'))
    .CateringDetailedInformation;
}
export async function CateringHeading(): LazyComponent {
  return (await import('profiles/catering/show/cateringComponents.rails'))
    .CateringHeading;
}
export async function ProfileImageCarousel(): LazyComponent {
  return (
    await import('profiles/common/show/components/ProfileImageCarousel.rails')
  ).default;
}
export async function CateringInquiry(): LazyComponent {
  return (await import('profiles/catering/show/cateringComponents.rails'))
    .CateringInquiry;
}
export async function CateringServicesAndPricing(): LazyComponent {
  return (await import('profiles/catering/show/cateringComponents.rails'))
    .CateringServicesAndPricing;
}
export async function CateringMenuList(): LazyComponent {
  return (await import('profiles/catering/show/cateringComponents.rails'))
    .CateringMenuList;
}

export async function SupplierProfileServicesAndPricing(): LazyComponent {
  return (
    await import(
      'profiles/suppliers/show/components/SupplierProfileServicesAndPricing.rails'
    )
  ).default;
}
export async function SupplierProfileHeading(): LazyComponent {
  return (
    await import(
      'profiles/suppliers/show/components/SupplierProfileHeading.rails'
    )
  ).default;
}
export async function SupplierProfileContactInformation(): LazyComponent {
  return (
    await import(
      'profiles/suppliers/show/components/SupplierProfileContactInformation.rails'
    )
  ).default;
}
export async function SupplierProfileVideo(): LazyComponent {
  return (
    await import(
      'profiles/suppliers/show/components/SupplierProfileVideo.rails'
    )
  ).default;
}
export async function SupplierProfileInquiry(): LazyComponent {
  return (
    await import(
      'profiles/suppliers/show/components/SupplierProfileInquiry.rails'
    )
  ).default;
}
export async function SupplierProfileContactImage(): LazyComponent {
  return (
    await import(
      'profiles/suppliers/show/components/SupplierProfileContactImage.rails'
    )
  ).default;
}
export async function SupplierProfileBasicInformation(): LazyComponent {
  return (
    await import(
      'profiles/suppliers/show/components/SupplierProfileBasicInformation.rails'
    )
  ).default;
}
export async function SupplierSearch(): LazyComponent {
  return (
    await import('profiles/suppliers/search/components/SupplierSearch.rails')
  ).default;
}

export async function LegalNotice(): LazyComponent {
  return (await import('common/components/inquiry/legalNotice/LegalNotice'))
    .default;
}

export async function PipedriveIntegrationStatus(): LazyComponent {
  return (
    await import(
      'staff/organization/components/pipedriveIntegrationStatus/PipedriveIntegrationStatus.rails'
    )
  ).default;
}

export async function BillingDetails(): LazyComponent {
  return (
    await import('billing/admin/billingDetails/components/BillingDetails.rails')
  ).default;
}
export async function TinField(): LazyComponent {
  return (await import('billing/admin/tin/components/TinField.rails')).default;
}
export async function InvoicesOfDayStates(): LazyComponent {
  return (
    await import(
      'billing/staff/dailyInvoices/components/InvoicesOfDayStates.rails'
    )
  ).default;
}
export async function InvoicingFields(): LazyComponent {
  return (
    await import(
      'billing/admin/invoicingFields/components/InvoicingFields.rails'
    )
  ).default;
}
export async function OrganizationInvoices(): LazyComponent {
  return (
    await import(
      'billing/staff/organizationInvoices/components/OrganizationInvoices.rails'
    )
  ).default;
}
export async function StripeCustomerStatus(): LazyComponent {
  return (
    await import(
      'billing/staff/stripeCustomerStatus/components/StripeCustomerStatus.rails'
    )
  ).default;
}

export async function OutcomeFeedback(): LazyComponent {
  return (await import('outcomes/thanks/components/OutcomeFeedback.rails'))
    .default;
}
